import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router5'

import PrimaryButton from 'src/components/atoms/PrimaryButton'
import TextInput from 'src/components/atoms/TextInput/TextInput'
import ErrorMessage from 'src/components/atoms/ErrorMessage'

import validateEmail from 'src/utils/validateEmail'
import { AUTH_FORGOT_PASSWORD } from 'src/router/routes'
import AppVersion from 'src/components/atoms/AppVersion'

const ForgotPasswordPrivacyFormFooter = styled.section`
  color: ${({ theme }) => theme.palette.text.secondary};
  display: flex;
  justify-content: space-between;
`
const PrivaryFormFooter = styled.section`
  display: flex;
  justify-content: flex-end;
`
const ForgotPasswordFormFooter = styled.section`
  display: flex;
`

const SubmitButton = styled(PrimaryButton)`
  && {
    margin-top: 16px;
    width: 100%;
  }
`

const StyledForm = styled.form`
  width: 100%;
`

const StyledFormFooter = styled.section`
  color: ${({ theme }) => theme.palette.text.secondary};
  display: flex;
  justify-content: flex-end;
  padding-top: 2em;
`

const FooterErrorMessage = styled.div`
  && {
    margin-right: auto;
  }
`

export const LoginForm = ({ onSubmit, error, inProgress }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState('')

  const isValidEmail = (value) => validateEmail(value)
  const isValidPassword = (value) => value.length
  const isFormValid = () => isValidEmail(email) && isValidPassword(password)

  const handleEmailChange = (event) => setEmail(event.target.value)
  const handlePasswordChange = (event) => setPassword(event.target.value)
  const handleEmailBlur = () =>
    setEmailError(
      email && !isValidEmail(email) ? 'Please enter a valid email address' : ''
    )
  const handleEmailFocus = () => setEmailError('')
  const handleSubmit = (event) => {
    event.preventDefault()
    if (isFormValid()) {
      onSubmit({ email, password })
    }
  }

  return (
    <>
      <StyledForm onSubmit={handleSubmit} disabled={inProgress}>
        <TextInput
          disabled={inProgress}
          fullWidth
          id="email"
          error={emailError}
          label="Email Address"
          name="email"
          onBlur={handleEmailBlur}
          onFocus={handleEmailFocus}
          onChange={handleEmailChange}
          placeholder="Your email"
          type="email"
        />
        <TextInput
          disabled={inProgress}
          fullWidth
          id="password"
          label="Password"
          name="password"
          onChange={handlePasswordChange}
          placeholder="Your password"
          type="password"
        />
        <SubmitButton type="submit" disabled={!isFormValid() || inProgress}>
          Login
        </SubmitButton>
        <StyledFormFooter>
          <FooterErrorMessage>
            <ErrorMessage active={!!error} message="Invalid credentials" />
          </FooterErrorMessage>
        </StyledFormFooter>
        <ForgotPasswordPrivacyFormFooter>
          <ForgotPasswordFormFooter>
            <Link routeName={AUTH_FORGOT_PASSWORD}>Forgot Password</Link>
          </ForgotPasswordFormFooter>
          <PrivaryFormFooter>
            <a href="https://www.trelleborg.com/en/marine-and-infrastructure/privacy-notice" target="_blank" title="Privacy Policy" rel="noopener noreferrer">Privacy Policy</a>
          </PrivaryFormFooter>
        </ForgotPasswordPrivacyFormFooter>
        <AppVersion mt={1} />
      </StyledForm>
    </>
  )
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.object,
  inProgress: PropTypes.bool,
}

export default LoginForm
